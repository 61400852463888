"use client";

/**
 * Third-party libraries.
 */
import {
  ApolloClient,
  ApolloProvider as ApolloProviderComponent,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
// import { ApolloLink, HttpLink, Observable } from "@apollo/client";
// import {
//   ApolloNextAppProvider,
//   NextSSRApolloClient,
//   NextSSRInMemoryCache,
//   SSRMultipartLink,
// } from "@apollo/experimental-nextjs-app-support/ssr";
// import { useAuth0 } from "@auth0/auth0-react";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

/**
 * Provides the Apollo client contexts. Put this on the top
 */
const ApolloClientProvider = ({ children }: React.PropsWithChildren) => {
  const webSocketLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_APOLLO_GRAPHQL_WEB_SOCKET_ENDPOINT,
      // shouldRetry: () => true,
      // retryAttempts: 3,
      // connectionParams() {
      //   return {
      //     Authorization: "",
      //   };
      // },
    })
  );

  /**
   * Apollo HTTP link which specifies the GraphQL server endpoint.
   */
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_APOLLO_GRAPHQL_ENDPOINT,
    credentials: "same-origin",
  });

  /**
   * Apollo Authentication link that appends the Auth0 authentication token to
   * the Graphql request header.
   */
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        // authorization: auth0Token ? `Bearer ${auth0Token}` : "",
      },
    };
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    webSocketLink,
    httpLink
  );

  /**
   * Apollo client.
   */
  const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
    name: process.env.NEXT_PUBLIC_APOLLO_CLIENT_NAME,
    version: "0.0.0",
  });

  //     return new NextSSRApolloClient({
  //       cache: new NextSSRInMemoryCache(),
  //       link: ApolloLink.from([
  //         new SSRMultipartLink({
  //           stripDefer: true,
  //         }),
  //         httpLink,
  //         authLink,
  //       ]),
  //     });

  return (
    <ApolloProviderComponent client={client}>
      {children}
    </ApolloProviderComponent>
  );
};

export default ApolloClientProvider;
